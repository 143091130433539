import dom from '../../../../../wrapper/DomWrapper';

function initStorefront(containerId, type, args = []) {
  return new Promise((resolve, reject) => {
    const instance = dom.window.Ecwid;

    if (!instance) reject(new Error('Ecwid instance not found'));

    if (typeof instance.destroy === 'function') instance.destroy();

    // eslint-disable-next-line no-underscore-dangle
    dom.window._xnext_initialization_scripts = [
      {
        widgetType: type,
        id: containerId,
        arg: args,
      },
    ];
    instance.init();
    dom.window.ecwid_onBodyDone();

    instance.OnPageLoaded.add((page) => {
      resolve(page);
    });
  });
}

export default initStorefront;
