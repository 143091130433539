import SelectWidget from 'choices.js';

import dom from '../../../../../wrapper/DomWrapper';

import {
  PRICE_MODIFIER_TYPES,
  PRODUCT_OPTION_TYPES,
  PRODUCT_OPTIONS_CHECKBOX_ITEM_TEMPLATE,
  PRODUCT_OPTIONS_GROUP_TEMPLATE,
  PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE,
  PRODUCT_OPTIONS_SELECT_ITEM_TEMPLATE,
} from './constants';

import { SELECT_CONFIG } from '../../../../Form/constants';

const OPTION_TYPE_CLASS = new Map([
  [PRODUCT_OPTION_TYPES.RADIO, 'radio'],
  [PRODUCT_OPTION_TYPES.CHECKBOX, 'checkbox'],
  [PRODUCT_OPTION_TYPES.SELECT, 'select'],
]);

class ProductOptions {
  constructor(
    type,
    name,
    choice,
    currencyFormatter = (price) => price,
    defaultChoiceIndex,
    required = false,
  ) {
    this.type = type;
    this.name = name;
    this.choice = choice;
    this.modifierType = PRICE_MODIFIER_TYPES.ABSOLUTE;
    this.currencyFormatter = currencyFormatter;
    this.default = defaultChoiceIndex;
    this.required = required;
    this.html = '';

    switch (type) {
      case PRODUCT_OPTION_TYPES.RADIO:
        this.createRadioList();
        break;
      case PRODUCT_OPTION_TYPES.CHECKBOX:
        this.createCheckboxList();
        break;
      case PRODUCT_OPTION_TYPES.SELECT:
        this.createSelectList();
        break;
      default:
        break;
    }
  }

  getModifierLabel = (modifier, type = PRICE_MODIFIER_TYPES.ABSOLUTE) => {
    if (modifier === 0) return '';

    const sign = modifier >= 0 ? '+' : '-';
    const absModifier = Math.abs(modifier);
    const formattedModifier = type === PRICE_MODIFIER_TYPES.ABSOLUTE
      ? this.currencyFormatter(absModifier)
      : `${absModifier}%`;

    return `(${sign}${formattedModifier})`;
  };

  createRadioList = () => {
    const options = this.choice.reduce((group, {
      priceModifier,
      priceModifierType,
      text,
    }, i) => {
      const modifierLabel = this.getModifierLabel(
        priceModifier,
        priceModifierType,
      );
      const isChecked = i === this.default;

      return group.concat(
        PRODUCT_OPTIONS_RADIO_ITEM_TEMPLATE(
          this.name,
          text,
          text,
          modifierLabel,
          isChecked,
        ),
      );
    }, '');

    this.html = PRODUCT_OPTIONS_GROUP_TEMPLATE(
      OPTION_TYPE_CLASS.get(this.type),
      this.name,
      options,
    );
  };

  createCheckboxList = () => {
    const options = this.choice.reduce((group, {
      priceModifier,
      priceModifierType,
      text,
    }, i) => {
      const modifierLabel = this.getModifierLabel(
        priceModifier,
        priceModifierType,
      );
      const isChecked = i === this.default;

      return group.concat(
        PRODUCT_OPTIONS_CHECKBOX_ITEM_TEMPLATE(
          this.name,
          text,
          text,
          modifierLabel,
          isChecked,
        ),
      );
    }, '');

    this.html = PRODUCT_OPTIONS_GROUP_TEMPLATE(
      OPTION_TYPE_CLASS.get(this.type),
      this.name,
      options,
    );
  };

  createSelectList = () => {
    this.html = PRODUCT_OPTIONS_GROUP_TEMPLATE(
      OPTION_TYPE_CLASS.get(this.type),
      this.name,
      PRODUCT_OPTIONS_SELECT_ITEM_TEMPLATE(this.name),
    );
  };

  // eslint-disable-next-line consistent-return
  connect = (element, onChange) => {
    switch (this.type) {
      case PRODUCT_OPTION_TYPES.RADIO:
        return this.connectRadioList(element, onChange);
      case PRODUCT_OPTION_TYPES.CHECKBOX:
        return this.connectCheckboxList(element, onChange);
      case PRODUCT_OPTION_TYPES.SELECT:
        return this.connectSelectList(element, onChange);
      default:
        break;
    }
  };

  connectRadioList = (
    element,
    onChange = () => null,
  ) => {
    const optionsElement = dom.createElement('div');
    dom.addHtml(optionsElement, this.html);
    element.appendChild(optionsElement);

    const inputs = dom.getCollection(`.radio-wrap>input[name="${this.name}"]`, optionsElement);

    inputs.forEach((input) => {
      dom.on(input, 'change', onChange);
    });
  };

  connectCheckboxList = (element, onChange) => {
    const optionsElement = dom.createElement('div');
    dom.addHtml(optionsElement, this.html);
    element.appendChild(optionsElement);

    const inputs = dom.getCollection(`.checkbox-wrap>input[name="${this.name}"]`, optionsElement);

    inputs.forEach((input) => {
      dom.on(input, 'change', onChange);
    });
  };

  connectSelectList = (element, onChange) => {
    const optionsElement = dom.createElement('div');
    dom.addHtml(optionsElement, this.html);
    element.appendChild(optionsElement);

    const input = dom.getElement(`select[id="${this.name}"]`, optionsElement);
    const choices = this.choice.reduce((acc, {
      priceModifier,
      priceModifierType,
      text,
    }, index) => {
      const modifierLabel = this.getModifierLabel(
        priceModifier,
        priceModifierType,
      );
      const label = `<span class="choice-title">${text}<span class="choice-modifier">${modifierLabel}</span></span>`;
      acc.push({
        label,
        value: text,
        selected: index === this.default,
      });
      return acc;
    }, []);

    // eslint-disable-next-line no-unused-vars
    const select = new SelectWidget(input, { ...SELECT_CONFIG, shouldSort: false, choices });

    dom.on(input, 'change', onChange);
  };
}

export default ProductOptions;
