export const NAMES = {
  // Sticky elements
  BACK_TO_TOP: 'back_to_top',
  PROMOTION: 'promotion',

  BLOCK: 'row_block',
  BLOCK_NEW: 'block',
  BUTTON: 'button',
  BUTTON_WRAP: 'button/div',
  BUTTON_DRAG: 'button/drag',
  DIVIDER: 'divider',
  FACEBOOK_COMMENTS: 'facebook_comments',
  FACEBOOK_LIKE: 'facebook_like',
  FACEBOOK_PAGE_WIDGET: 'facebook_page',
  FORM: 'form',
  TABLE: 'table',
  TABLE_HEAD_CELL: 't/head',
  TABLE_STANDARD_CELL: 't/cell',
  GALLERY: 'gallery',
  HEADER: 'header',
  HEADER_BUTTON: 'header_button',
  ICON: 'icon',
  IMAGE: 'image',
  INSTAGRAM: 'instagram',
  ITEM: 'row_item',
  LOGO: 'logo',
  MAP: 'map',
  NAVIGATION: 'navigation',
  REDACTOR: 'redactor',
  SECTION: 'builder/default',
  SECTION_NEW: 'section',
  SHAPE: 'shape',
  SLIDE: 'slide',
  SLIDER: 'slider',
  SLIDER_BG: 'slider_bg',
  SOCIAL: 'social',
  SOCIAL_SHARES: 'social_shares',
  SPACE: 'space',
  EMBED_BLOCK: 'embed_block',

  // Blog
  BLOG_POSTS_LIST: 'blog_category',
  BLOG_POST: 'blog_post',

  ECOMMERCE_CATALOGUE: 'ecommerce_catalogue',
  ECOMMERCE_PRODUCT_PAGE: 'ecommerce_product_page',
  ECOMMERCE_CART_PAGE: 'ecommerce_cart_page',
  ECOMMERCE_SHIPPING_PAGE: 'ecommerce_shipping_page',

  // Ecommerce dnd containers
  ECOMMERCE_LIST: 'ecommerce_list',
  ECOMMERCE_PRODUCT: 'ecommerce_product',

  // Ecommerce dnd components
  ECOMMERCE_OPTIONS: 'ecommerce_options',
  ECOMMERCE_PHOTO: 'ecommerce_photo',
  ECOMMERCE_TEXT: 'ecommerce_text',
  ECOMMERCE_BUTTON: 'ecommerce_button',

  // Ecommerce components
  // ECOMMERCE_RADIO: 'ecommerce_radio',
  ECOMMERCE_SELECT: 'ecommerce_select',
  // ECOMMERCE_CHECKBOX: 'ecommerce_checkbox',
  // ECOMMERCE_DATE: 'ecommerce_date',

  SHOPPING_CART: 'shopping_cart',

  TEMPLATE_HEADER: 'template_header',
  TWITTER_POST: 'twitter_post',
  TWITTER_TIMELINE: 'twitter_timeline',
  VIDEO: 'video',
};

export const SECTION = {
  MAP: 'map',
  IMAGE: 'image',
  VIDEO: 'video',
  SLIDER: 'slider',
};

export const FB = 'https://facebook.com';

export const SOCIAL_ICON_TYPES = {
  fb: {
    label: 'Facebook',
    url: ['facebook.com/', 'fb.com/'],
    mask: 'facebook.com/%mask%',
    icon: 'facebook',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9.]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((facebook|fb)\.com\/[a-zA-Z0-9.]+\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://www.facebook.com/sharer.php?u=${href}`,
  },
  twitter: {
    label: 'Twitter',
    url: 'twitter.com/',
    mask: 'twitter.com/%mask%',
    icon: 'twitter',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^(?!(^(twitter|admin)$))([a-zA-Z0-9_]){1,15}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((twitter)\.com\/[a-zA-Z0-9_]{1,15}\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://twitter.com/intent/tweet?text=${href}`,
  },
  instagram: {
    label: 'Instagram',
    url: 'instagram.com/',
    mask: 'instagram.com/%mask%',
    icon: 'instagram',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]{1,30}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((instagram)\.com\/[a-zA-Z0-9_.]{1,30}\/?))$/,
      },
    },
    // no share
  },
  email: {
    label: 'Email',
    url: '',
    mask: '',
    protocol: 'mailto:',
    icon: 'email',
    address: '',
    subject: 'Check out my awesome website',
    themeColors: true,
    canBeOpenedInNewTab: false,
    validations: {
      profileName: {
        // eslint-disable-next-line no-control-regex
        regex: /(^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$)|(^\s*$)/i,
      },
    },
    getShareUrl: ({ address, subject, href }) => `mailto:${address}?subject=${subject}&body=${href}`,
  },
  linkedin: {
    label: 'Linkedin',
    url: 'linkedin.com/',
    mask: 'linkedin.com/in/%mask%',
    icon: 'linkedin',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9-]{5,30}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((linkedin)\.com\/(in|company)\/[a-zA-Z0-9-]{5,30}\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://www.linkedin.com/shareArticle?url=${href}`,
  },
  pinterest: {
    label: 'Pinterest',
    url: 'pinterest.com/',
    mask: 'pinterest.com/%mask%',
    icon: 'pinterest',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_]{3,30}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((pinterest)\.com\/[a-zA-Z0-9_]{3,30}\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://pinterest.com/pin/create/bookmarklet?url=${href}`,
  },
  etsy: {
    label: 'Etsy',
    url: 'etsy.com/',
    mask: 'etsy.com/shop/%mask%',
    icon: 'etsy',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9]{1,20}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((etsy\.com\/shop\/[a-zA-Z0-9]{1,20}|([a-zA-Z0-9]{1,20}\.etsy\.com))\/?))$/,
      },
    },
  },
  yelp: {
    label: 'Yelp!',
    url: 'yelp.com/',
    mask: 'yelp.com/biz/%mask%',
    icon: 'yelp',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.-]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((yelp)\.com\/(biz\/)?[a-zA-Z0-9_.-]+\/?))$/,
      },
    },
  },
  fbmessenger: {
    label: 'Facebook messenger',
    icon: 'fbmessenger',
    url: ['messenger.com/', 'm.me/'],
    mask: 'm.me/%mask%',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^([a-zA-Z0-9.]){5,}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(m\.me\/[a-zA-Z0-9.]{5,}\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `fb-messenger://share?link=${href}`,
  },
  tiktok: {
    label: 'Tik Tok',
    url: 'tiktok.com/',
    mask: 'tiktok.com/@%mask%',
    icon: 'tiktok',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((tiktok)\.com\/@[a-zA-Z0-9_.]+\/?))$/,
      },
    },
  },
  telegram: {
    label: 'Telegram',
    url: ['telegram.org/', 't.me/'],
    mask: 't.me/%mask%',
    icon: 'telegram',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_]{5,}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(t(elegram)?\.(me|org)\/[a-zA-Z0-9_]{5,}\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://t.me/share/url?url=${href}`,
  },
  wechat: {
    label: 'We chat',
    url: 'wechat.com/',
    mask: '//dl/chat?%mask%',
    icon: 'wechat',
    protocol: 'weixin:',
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9]*$/,
      },
      linkUrl: {
        regex: /^weixin:\/\/dl\/chat\?[a-zA-Z0-9]+\/?$/,
      },
    },
    canBeOpenedInNewTab: true,
  },
  skype: {
    label: 'Skype',
    url: 'skype.com/',
    mask: '',
    icon: 'skype',
    protocol: 'skype:',
    validations: {
      profileName: {
        regex: /^((?!^[-.]|[-.]+$)[a-zA-Z0-9_\-.])+$/,
      },
      linkUrl: {
        regex: /^(((https?:\/\/)?(www\.)?(join\.skype\.com\/(invite\/)?|skype:)[^-.]((?![-.]$)[a-zA-Z0-9_\-.])*\/?))$/,
      },
    },
    canBeOpenedInNewTab: true,
  },
  viber: {
    label: 'Viber',
    icon: 'viber',
    url: 'viber.com/',
    mask: {
      phoneNumber: '//add?number=%mask%',
      accountName: '//public?id=%mask%',
    },
    protocol: 'viber:',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]+$/,
      },
      linkUrl: {
        regex: /^viber?:\/\/((add\?number=[+ 0-9]+$)|(public\?id=[a-zA-Z0-9_.]+\/?$))/,
      },
      phone: {
        regex: /^[+ 0-9]+$/,
      },
    },
    getShareUrl: ({ href }) => `viber://forward?text=${href}`,
  },
  snapchat: {
    label: 'Snapchat',
    url: 'snapchat.com/',
    mask: 'snapchat.com/add/%mask%',
    icon: 'snapchat',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z][a-zA-Z0-9._-]{1,13}[a-zA-Z0-9]$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(snapchat\.com\/add\/[a-zA-Z][a-zA-Z0-9._-]{1,11}[a-zA-Z0-9]\/?))$/,
      },
    },
  },
  qq: {
    label: 'QQ',
    url: 'imqq.com/',
    mask: '', // just link same as email f.e.
    icon: 'qq',
    canBeOpenedInNewTab: true,
    validations: {
      linkUrl: {
        regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+\/?$/,
      },
    },
  },
  youtube: {
    label: 'Youtube',
    url: 'youtube.com/',
    mask: 'youtube.com/channel/%mask%',
    icon: 'youtube',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9\-_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(youtube\.com\/channel\/[a-zA-Z0-9_.]+\/?))$/,
      },
    },
    // no share
  },
  vimeo: {
    label: 'Vimeo',
    url: 'vimeo.com/',
    mask: 'vimeo.com/%mask%',
    icon: 'vimeo',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9]{3,23}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(vimeo\.com\/[a-zA-Z0-9]{3,23}\/?))$/,
      },
    },
    // no share
  },
  flickr: {
    label: 'Flickr',
    url: 'flickr.com/',
    mask: 'flickr.com/people/%mask%',
    icon: 'flickr',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9\-_@]{1,32}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(flickr\.com\/people\/[a-zA-Z0-9\-_@]{1,32}\/?))$/,
      },
    },
    // no share
  },
  periscope: {
    label: 'Periscope',
    url: 'pscp.tv/',
    mask: 'pscp.tv/%mask%',
    icon: 'periscope',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_]{2,20}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(pscp\.tv\/[a-zA-Z0-9_]{2,20}\/?$))/,
      },
    },
    // no share
  },
  spotify: {
    label: 'Spotify',
    url: 'spotify.com/',
    mask: 'open.spotify.com/user/%mask%',
    icon: 'spotify',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9-_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(open\.spotify\.com\/user\/[a-zA-Z0-9-_]+\/?))$/,
      },
    },
    // no share
  },
  soundcloud: {
    label: 'Soundcloud',
    url: 'soundcloud.com/',
    mask: 'soundcloud.com/%mask%',
    icon: 'soundcloud',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^([a-z0-9](?![-_]{2}))([a-z0-9-_](?![-_]{2})){1,23}[a-z0-9]$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(soundcloud\.com\/([a-zA-Z0-9](?![-_]{2}))([a-zA-Z0-9-_](?![-_]{2})){1,23}[a-zA-Z0-9]\/?))$/,
      },
    },
    // no share
  },
  reddit: {
    label: 'Reddit',
    url: 'reddit.com/',
    mask: 'reddit.com/r/%mask%',
    icon: 'reddit',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9-_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(reddit\.com\/r\/[a-zA-Z0-9-_]+\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://reddit.com/submit?url=${href}`,
  },
  github: {
    label: 'Github',
    url: 'github.com/',
    mask: 'github.com/%mask%',
    icon: 'github',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9-_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(github\.com\/[a-zA-Z0-9-_]+\/?))$/,
      },
    },
    // no share
  },
  tumblr: {
    label: 'Tumblr',
    url: 'tumblr.com/',
    mask: '',
    icon: 'tumblr',
    canBeOpenedInNewTab: true,
    validations: {
      linkUrl: {
        regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+\/?$/,
      },
    },
    getShareUrl: ({ href }) => `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${href}`,
  },
  blogger: {
    label: 'Blogger',
    url: 'blogspot.com/',
    mask: '%mask%.blogspot.com/',
    icon: 'blogger',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?([a-zA-Z0-9_]+\.blogspot\.com\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://www.blogger.com/blog-this.g?u=${href}`,
  },
  deviantart: {
    label: 'Deviantart',
    url: 'deviantart.com/',
    mask: '%mask%.deviantart.com/',
    icon: 'deviantart',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9-_]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?([a-zA-Z0-9-_]+\.deviantart\.com|deviantart\.com\/[a-zA-Z0-9-_]+)\/?)$/,
      },
    },
    // no share
  },
  livejournal: {
    label: 'LiveJournal',
    url: 'livejournal.com/',
    mask: '%mask%.livejournal.com/',
    icon: 'livejournal',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^(?!(news_|ex_|ext_|lj_|s_))([a-z0-9]([a-z0-9_](?!_{2})){0,13}[a-z0-9])$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?((?!(news_|ex_|ext_|lj_|s_))([a-z0-9]([a-z0-9_](?!_{2})){0,13}[a-z0-9])\.livejournal\.com\/|users\.livejournal\.com\/(?!(news_|ex_|ext_|lj_|s_))([a-z0-9]([a-z0-9_](?!_{2})){0,13}[a-z0-9]))\/?)$/,
      },
    },
    getShareUrl: ({ href }) => `http://www.livejournal.com/update.bml?event=${href}`,
  },
  behance: {
    label: 'Behance',
    url: ['behance.net/', 'be.net/'],
    mask: 'behance.net/%mask%',
    icon: 'behance',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9-_]{3,20}$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(be(hance)?\.net\/(?=.*[a-zA-Z])[a-zA-Z0-9-_]{3,20}\/?))$/,
      },
    },
    // no share
  },
  dribble: {
    label: 'Dribbble',
    url: 'dribbble.com/',
    mask: 'dribbble.com/%mask%',
    icon: 'dribbble',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_-]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(dribbble\.com\/[a-zA-Z0-9_-]+\/?))$/,
      },
    },
    // no share
  },
  whatsapp: {
    label: 'WhatsApp',
    icon: 'whatsapp',
    url: 'whatsapp.com/',
    mask: '//send?phone=%mask%',
    protocol: 'whatsapp:',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[0-9]+$/,
      },
      linkUrl: {
        regex: /^whatsapp:\/\/send\?phone=\d+?$/,
      },
    },
    getShareUrl: ({ href }) => `whatsapp://send?text=${href}`,
  },
  myspace: {
    label: 'MySpace',
    url: 'myspace.com/',
    mask: 'myspace.com/%mask%',
    icon: 'myspace',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]*$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(myspace\.com\/[a-zA-Z0-9_.]+\/?))$/,
      },
    },
  },
  vk: {
    label: 'Vk',
    url: 'vk.com/',
    mask: 'vk.com/%mask%',
    icon: 'vk',
    canBeOpenedInNewTab: true,
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]*$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(vk\.com\/[a-zA-Z0-9_.]+))$\/?/,
      },
    },
    getShareUrl: ({ href }) => `http://vk.com/share.php?url=${href}`,
  },
  rss: {
    label: 'Rss',
    url: '', // just link same as email f.e.
    mask: '',
    icon: 'rss',
    canBeOpenedInNewTab: true,
    validations: {
      linkUrl: {
        regex: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+\/?$/,
      },
    },
    // no share
  },
  copylink: {
    label: 'Copy Link',
    icon: 'copylink',
    themeColors: true,
    getShareUrl: () => '',
  },
  googleplus: {
    label: 'Google',
    url: 'plus.google.com/',
    mask: 'plus.google.com/%mask%',
    deprecated: true,
    icon: 'googleplus',
    validations: {
      profileName: {
        regex: /^[a-zA-Z0-9_.]+$/,
      },
      linkUrl: {
        regex: /^((https?:\/\/)?(www\.)?(plus\.google\.com\/[a-zA-Z0-9-_]+\/?))$/,
      },
    },
    getShareUrl: ({ href }) => `https://plus.google.com/share?url=${href}`,
  },
  pocket: {
    label: 'Pocket',
    icon: 'pocket',
    getShareUrl: ({ href }) => `https://getpocket.com/save?url=${href}`,
  },
  hackernews: {
    label: 'Hacker News',
    icon: 'hackernews',
    getShareUrl: ({ href }) => `https://news.ycombinator.com/submitlink?u=${href}`,
  },
};

export const TWEETS_LIMIT = 3;

export const DEFAULT_STYLES = `
#mobile_table {
  display: none;
}

@media screen and (max-width: 1040px){
  #desktop_table {
    display: none;
  }

  #mobile_table {
    display: block;
  }
}`;

export const TABLE = {
  BODY: 't/cell',
  HEAD: 't/head',
};

export const VIDEO_PROVIDER_NAMES = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  DAILYMOTION: 'dailyimotion',
};

export const SLIDER = {
  ANIMATION_SPEED: 500,
  BACKGROUNDS: {
    MAP: 'map',
    VIDEO: 'video',
    PHOTO: 'image',
  },
};

export const brand = 'rgb(var(--preColor_brand))';
export const altBrand = 'rgb(var(--preColor_altBrand))';
export const THEMES = [
  {
    src: 'builder/social_preview/small-theme-1.png',
    activeSrc: 'builder/social_preview/theme-1.png',
    fillColorLabel: 'se.wf.social_design_fill-color_icon',
    colorLabel: 'se.wf.social_design_color_hover',
    fillColor: brand,
    color: altBrand,
  },
  {
    src: 'builder/social_preview/small-theme-2.png',
    activeSrc: 'builder/social_preview/theme-2.png',
    fillColorLabel: 'se.wf.social_design_fill-color_icon',
    colorLabel: 'se.wf.social_design_color_background',
    fillColor: brand,
    color: altBrand,
  },
  {
    src: 'builder/social_preview/small-theme-3.png',
    activeSrc: 'builder/social_preview/theme-3.png',
    fillColorLabel: 'se.wf.social_design_fill-color_background',
    colorLabel: 'se.wf.social_design_color_hover',
    fillColor: brand,
    color: altBrand,
    scalingStyles: {
      padding: {
        min: 7,
        max: 14,
      },
    },
  },
  {
    src: 'builder/social_preview/small-theme-4.png',
    activeSrc: 'builder/social_preview/theme-4.png',
    colorLabel: 'se.wf.social_design_color_icon',
    color: brand,
    scalingStyles: {
      padding: {
        min: 4,
        max: 14,
      },
      borderWidth: {
        min: 2,
        max: 4,
      },
    },
  },
  {
    src: 'builder/social_preview/small-theme-5.png',
    activeSrc: 'builder/social_preview/theme-5.png',
    colorLabel: 'se.wf.social_design_color_icon',
    color: brand,
    scalingStyles: {
      padding: {
        min: 4,
        max: 14,
      },
      borderWidth: {
        min: 2,
        max: 4,
      },
    },
  },
  {
    src: 'builder/social_preview/small-theme-6.png',
    activeSrc: 'builder/social_preview/theme-6.png',
  },
];

export const ICON_SIZE = {
  min: 30,
  max: 80,
  step: 5,
};

export const className = {
  toolbar: 'toolbar_header',
  ul: 'toolbar__tabs-nav',
};

export const SIDEBAR_ELEMENTS = {
  items: [
    {
      title: 'Basic',
      items: [
        {
          name: 'heading',
        },
        {
          name: 'text',
        },
        {
          name: NAMES.BUTTON,
        },
        {
          name: NAMES.ICON,
        },
        {
          name: NAMES.IMAGE,
        },
        {
          name: NAMES.VIDEO,
        },
        {
          name: NAMES.GALLERY,
        },
        {
          name: NAMES.SLIDER,
        },
        {
          name: NAMES.FORM,
        },
        {
          name: NAMES.MAP,
        },
      ],
    },
    {
      title: 'Layout',
      items: [
        {
          name: NAMES.SPACE,
        },
        {
          name: NAMES.SHAPE,
        },
        {
          name: NAMES.DIVIDER,
        },
      ],
    },
    {
      title: 'Online Store',
      items: [
        {
          name: NAMES.ECOMMERCE_PRODUCT,
        },
        {
          name: NAMES.ECOMMERCE_CATALOGUE,
        },
      ],
    },
    {
      title: 'Social',
      items: [
        {
          name: NAMES.SOCIAL,
        },
        {
          name: NAMES.SOCIAL_SHARES,
        },
        {
          name: NAMES.INSTAGRAM,
        },
        {
          name: NAMES.FACEBOOK_LIKE,
        },
        {
          name: NAMES.FACEBOOK_PAGE_WIDGET,
        },
        {
          name: NAMES.FACEBOOK_COMMENTS,
        },
        {
          name: NAMES.TWITTER_POST,
        },
        {
          name: NAMES.TWITTER_TIMELINE,
        },
      ],
    },
    {
      title: 'Advanced',
      items: [
        {
          name: NAMES.EMBED_BLOCK,
        },
      ],
    },
  ].filter(Boolean),
};

export const DEFAULT_ICONS = [
  'global/check',
  'sp-cross_20',
  'sp-arrow-next',
  'sp-arrow-next',
];
