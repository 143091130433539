import dom from '../../wrapper/DomWrapper';

class GoogleAnalytics {
  constructor({ gaTrackingId, gtmContainerId }) {
    this.gaTrackingId = gaTrackingId;
    this.gtmContainerId = gtmContainerId;
  }

  init = () => {
    const { head } = dom.document;

    if (this.gaTrackingId) {
      const analyticSnippetEl = this.createAnalyticSnippet(this.gaTrackingId);
      head.insertAdjacentElement('afterbegin', analyticSnippetEl);
    }

    if (this.gtmContainerId) {
      const gtmSnippetEl = this.createGoogleTagManagerSnippet();
      const gtmNoScriptEl = this.createGoogleTagManagerNoScript();
      head.insertAdjacentElement('afterbegin', gtmSnippetEl);
      dom.document.body.insertAdjacentElement('afterbegin', gtmNoScriptEl);
    }
  }

  /**
   * Create script snippet according to this docs
   * https://developers.google.com/analytics/devguides/collection/analyticsjs/
   */
  createAnalyticSnippet = (trackingId) => {
    const script = dom.createElement('script');
    const cookieDomain = dom.document.location.host;
    const createSettings = {
      trackingId,
      cookieDomain,
    };
    const strCreateSettings = JSON.stringify(createSettings);
    script.type = 'text/javascript';
    script.text = `
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', ${strCreateSettings});
    ga('send', 'pageview');
  `;

    return script;
  }

  /**
   * Create script snippet according to this docs
   * https://developers.google.com/tag-manager/quickstart
   */
  createGoogleTagManagerSnippet = () => {
    const script = dom.createElement('script');
    script.type = 'text/javascript';
    script.text = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${this.gtmContainerId}');
  `;

    return script;
  };

  /**
   * Create script snippet according to this docs
   * https://developers.google.com/tag-manager/quickstart
   */
  createGoogleTagManagerNoScript = () => {
    const noscriptEl = dom.createElement('noscript');
    const iframeEl = dom.createElement('iframe');
    iframeEl.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmContainerId}`;
    iframeEl.height = 0;
    iframeEl.width = 0;
    iframeEl.style.display = 'none';
    iframeEl.style.visibility = 'hidden';
    noscriptEl.appendChild(iframeEl);

    return noscriptEl;
  };
}

export default GoogleAnalytics;
