import Cookies from 'js-cookie';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

import dom from '../../wrapper/DomWrapper';
import { checkIsAnalyticsEnabled } from './utils';

import {
  ANALYTICS_COOKIE_KEY,
  EU_COUNTRIES_LIST,
  COOKIE_SETTINGS_ANALITYCS_DEFAULTS,
  PLATFORM_ANALYTICS_COOKIE_KEYS,
  COOKIE_REMOVE_TIMEOUT,
} from './constants';

class CookieAnalyticsManager {
  checkIsAlreadyHandled = () => !!Cookies.get(ANALYTICS_COOKIE_KEY)

  updateGeoLocation = (location) => { this.userGeoLocation = location; };

  checkIsEurope = () => {
    const country = this.userGeoLocation || Cookies.get('country');

    if (!isString(country)) return true;

    return EU_COUNTRIES_LIST.indexOf(country.toUpperCase()) >= 0;
  };

  updateCountry = () => {
    const isLocationExists = !!this.userGeoLocation;

    if (isLocationExists) Cookies.set('country', this.userGeoLocation);
  }

  getInitialValue = () => {
    const analyticsDefault = this.checkIsEurope()
      ? COOKIE_SETTINGS_ANALITYCS_DEFAULTS.isEuro
      : COOKIE_SETTINGS_ANALITYCS_DEFAULTS.global;
    const analyticsInitial = checkIsAnalyticsEnabled() ? analyticsDefault : 0;
    const analyticsCookie = Cookies.get(ANALYTICS_COOKIE_KEY);
    const binaryValue = Number(analyticsCookie) === 1 ? 1 : 0;

    return !isNil(analyticsCookie) ? binaryValue : analyticsInitial;
  }

  getCookies = () => Cookies.get();

  setCookies = (key, value, options = {}) => Cookies.set(key, value, options);

  removeAnalytics = () => {
    setTimeout(() => {
      this.removePlatformAnalytics();
      this.removeGoogleAnalytics();
    }, COOKIE_REMOVE_TIMEOUT);
  }

  removePlatformAnalytics = () => PLATFORM_ANALYTICS_COOKIE_KEYS
    .forEach((item) => Cookies.remove(item));

  removeGoogleAnalytics = () => {
    const attributes = { path: '/', domain: `.${dom.document.domain}` };

    const gaPrefixes = ['_gat_', '_dc_gtm_'];
    const regexPrefixesPart = `(${gaPrefixes.join('|')})`;
    const regexpPattern = new RegExp(`${regexPrefixesPart}.[^;]*`, 'g');
    const matched = dom.document.cookie.match(regexpPattern);

    if (matched) {
      const ids = matched.map((item) => {
        const [name] = item.split('=');
        Cookies.remove(name, attributes);
        return name.replace(name, '');
      });
      ids.forEach((id) => {
        dom.window[`ga-disable-${id}`] = true;
      });
    }

    const gaCookies = [...gaPrefixes, '_ga', '_gat', '_gid'];

    gaCookies.forEach((item) => Cookies.remove(item));
  };

  saveSettingsToCookies = (value) => {
    Cookies.set(ANALYTICS_COOKIE_KEY, value);
  }
}

export default CookieAnalyticsManager;
